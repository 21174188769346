
import { ref, defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
  props: {
    textData: {
      type: Object,
      required: true,
    },
  },
  setup: () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const showPic = ref('');
    const loaded = ref(false);
    // var a = 'dfsdfsd';
    return {
      showPic,
      loaded,
    };
  },
});
