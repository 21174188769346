
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Tabs',
  props: {
    show: {
      type: Boolean,
    },
    textData: {
      type: Object,
      required: true,
    },
    currentTab: {
      type: String,
      required: true,
    },
  },
  emits: ['change'],
  setup: (_props, { emit }) => {
    const handleTabClick = (name: string) => {
      emit('change', name);
    };
    return {
      handleTabClick,
    };
  },
});
